import { useEffect, useState } from 'react'
import styles from './reviews.module.scss'
import Heading from 'components/heading/heading'
import { cn } from 'utils/helpers'
import Svg from 'components/svg/svg'
import { get } from 'utils/api'
import Link from 'next/link'

export default function Reviews() {
    const [data, setData] = useState({})
    const [item, setItem] = useState({})

    useEffect(() => {
        const check = async () => {
            const response = await get('home-reviews-info')
            setData(response.data)
            setItem(response.data.items[Math.floor(Math.random() * response.data.items.length)])
        }

        check();
    },[])


    return (
        <div className={cn(styles.container, 'mb72')}>
            <div className={styles.content}>
                <Svg className={styles.top} name="paper-top" />
                <Svg className={styles.bottom} name="paper-bottom" />
                <div className={styles.wrap}>
                    <Heading size="30" tag="h2">
                        Pirkėjai vertina <span>„Lietuviška prekė“</span>
                    </Heading>
                    <div className={styles.center}>
                        <div>
                            Pirkėjai įvertino: <span>{data.average}/5</span>
                        </div>
                        <div className={styles.main}>
                            <div>
                                <div>
                                    <Svg name="star" />
                                </div>
                            </div>
                            <div>
                                <div>
                                    <Svg name="star" />
                                </div>
                            </div>
                            <div>
                                <div>
                                    <Svg name="star" />
                                </div>
                            </div>
                            <div>
                                <div>
                                    <Svg name="star" />
                                </div>
                            </div>
                            <div>
                                <div className={styles.darker}>
                                    <Svg name="star" />
                                </div>
                                <div style={{width: '71%'}}>
                                    <Svg name="star" />
                                </div>
                            </div>
                        </div>
                        <div>
                            {data.total} įvertinimų. 
                            <Link href="/atsiliepimai">
                                <a>Žiūrėti visus</a>
                            </Link>
                        </div>
                    </div>
                    {item && (<>
                        <div className={styles.review}>
                            <Svg name="quote" />
                            {item.content}
                            <Svg name="quote" />
                        </div>
                        <div className={styles.name}>
                            {item.name}
                        </div>
                    </>)}
                </div>
            </div>
        </div>
    )
}
